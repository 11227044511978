import React from "react";
import { Link } from "react-router-dom";
// import { useContractReader } from "eth-hooks";
// import { ethers } from "ethers";
import { Button, Divider, Typography, Layout, Tabs} from "antd";
import { useLookupAddress } from "eth-hooks/dapps/ens";
import { useEventListener } from "eth-hooks/events/useEventListener";
import { ethers } from "ethers";

import Message from "./Message";
import "./Message.scss";

/**
 * web3 props can be passed from '../App.jsx' into your local view component for use
 * @param {*} yourLocalBalance balance on current network
 * @param {*} readContracts contracts from current chain already pre-loaded using ethers contract module. More here https://docs.ethers.io/v5/api/contract/contract/
 * @returns react component
 */
function Home({ localProvider, readContract, loadWeb3Modal, blockExplorer, openSea,
                messageForm, mainnetProvider, address, price }) {
  
  const { Title, Paragraph, Text } = Typography;
  
  const { TabPane } = Tabs;
  
  var lookedUpAddress = useLookupAddress(mainnetProvider, address)
  
  var fancyBaseCost = ethers.BigNumber.from(8758200 * 1e9)
  var fancyBaseEther = ethers.utils.formatEther(fancyBaseCost)
  var fancyBaseUSD = (fancyBaseEther * price).toFixed(2)
  
  var fancyVariableCost = ethers.BigNumber.from(21600 * 1e9)
  var fancyVariableEther = ethers.utils.formatEther(fancyVariableCost)
  var fancyVariableUSD = (fancyVariableEther * price).toFixed(2)
  
  var tweetCost = ((fancyBaseEther * price) + (fancyVariableEther * price * 280)).toFixed(2)
  
  const events = useEventListener(readContracts, "ForeverMessage", "CreateMessage", localProvider, 1);
  
  var decimalToHex = function(d, padding = 6) {
    var hex = Number(d).toString(16);
    padding = typeof (padding) === "undefined" || padding === null ? padding = 2 : padding;

    while (hex.length < padding) {
      hex = "0" + hex;
    }

    return "#" + hex;
  }
  
  const foreverMessageAddress = readContracts.ForeverMessage && readContracts.ForeverMessage.address
  
  var basicMessages = events.map(event => {
    return {
      text: event.args.text,
      timestamp: event.args.sentAt,
      senderAddress: event.args.sender,
      messageId: parseInt(event.args.messageId),
      fancyMessageId: parseInt(event.args.fancyMessageId),
      recipient: event.args.recipient,
      inReplyToMessageId: parseInt(event.args.inReplyToMessageId),
      textColor: decimalToHex(event.args.textColor),
      transactionHash: event.transactionHash,
      contract: event.address,
      gradientColors: event.args.gradientColors,
      isRadialGradient: event.args.isRadialGradient,
      linearGradientAngleDeg: event.args.linearGradientAngleDeg,
      fontSize: event.args.fontSize
    }
  })
    
  const { Content } = Layout
  
  return (
    <Content style={{fontSize: 16, paddingTop: 50}}>
      {/* <img src="/bubble.png"></img> */}
      {/* <h1 style={{textAlign: 'center', backgroundImage: "url(/bubble.png)", backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center', height: 200, display: "flex", alignItems: 'center', justifyContent: 'center',
      paddingBottom: 35, color: 'white'}}>Welcome to foreverMessage!</h1> */}
      
      {/* <h1>Welcome to foreverMessage</h1> */}
      <Title level={2}>Welcome to PhunksV1Wrapper</Title>
      {/* <Title level={2}>Welcome to foreverMessage</Title> */}
      {/* <Title level={4}>What would you say if you knew it would last forever?</Title> */}
      
      {/* <h2 style={{textAlign: 'center'}} >💬</h2> */}
      
      {/* <h4 style={{marginBottom: '15px'}}>
        What would you say if you knew it would last forever?
      </h4> */}
      
      <p style={{fontSize: 21}}>
       De-Philip-ify your V1 Phunk!
      </p>
      
      <p>
        <a href="https://opensea.io/collection/philipinternproject">Original V1 Phunks (OpenSea)</a>
        { " | " }
        <a href="https://opensea.io/collection/official-v1-phunks">Wrapped V1 Phunks (OpenSea)</a>
        { " | " }
        <a href="https://etherscan.io/address/0x235d49774139c218034c0571ba8f717773edd923#code">Verified Contract</a>
      </p>
      
      {/* <p>
        No more Philip
      </p> */}
      
      {messageForm}


    </Content>
  );
}

export default Home;
